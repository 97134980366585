import React, { Component } from "react";
import Daterange from "../Daterange";
import { addDays } from 'date-fns';
import OTGgrid from '../DataGrid/GridsController';
import {CardContent,Box,CardHeader,Button} from '@mui/material';
export class ASN extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      date :[ addDays(new Date(), -7), new Date() ],
      key:1,
      button: false,
      open:true,
      sessionID:this.props.sessionID
    };
    this.datechange   = this.datechange.bind(this);
    this.passdate     = this.passdate.bind(this);
  }

datechange(dates){
  this.setState(currentstate=>{
    return {date:dates};
  });
}
passdate(){
  this.setState(currentstate=>{
    return {key:currentstate.key+1};
  });
}


  render() {
    const {button,key,date,sessionID} = this.state;
    return (
      <>
      <CardHeader sx={{ padding: '25px 16px 5px 16px'}}
      title="ASN"
      action={
        <Box sx={{display: 'flex', pr: 2 }}>    
          <Box sx={{display: 'flex', alignItems: 'center', ml: 'auto'}}>
            <Daterange
              date={this.datechange}
              button ={button}
            />
            <Button variant="outlined" onClick={this.passdate} sx={{ml:2}}>submit</Button>
          </Box>
        </Box>
      }
    />
    <CardContent>
    <OTGgrid key={key} date={date} modulename="ASN" sessionID={sessionID}/>
    </CardContent>
    </>
    );
  }
}
export default ASN;
