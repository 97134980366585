import React, { Component } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';
// import { DateRangePicker } from 'react-date-range';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
class Daterange extends Component {
    
    constructor(props) {
        super(props);
        this.state = { date :[ addDays(new Date(), -7),new Date()],
      sk:[null, null] }
        this.props.date([ addDays(new Date(), -7),new Date()]);

    }
    setpagesizes(e) {
      this.setState(currentdate=>{
        return {date:e}
      },()=>{
        this.props.date(this.state.date);
      });
      
    }
    render() { 
      
        return (
            <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start-date"
              endText="End-date"
              value={this.state.date}
              onChange={item => this.setpagesizes(item)}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField size="small" {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField size="small" {...endProps} />
                </React.Fragment>
              )}
            />
    </LocalizationProvider>
          </div>
          );
    }
}
 
export default Daterange;