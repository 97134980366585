import React,{useState,useEffect,useContext}  from "react";
import axios from 'axios';
import {Box,IconButton, Avatar,Typography,Popover ,Tooltip,} from '@mui/material';
import { Close } from '@mui/icons-material';
import './Userinfo.css';
import { API_url } from "../../API_url/API_url";
import Logoutapi from "../logout/Logout";
import {Categoriescontext} from '../../context/authvendx';
export default function Userinfo(props) {
  const sessionID = props.sessionID;
  const [anchorEl, setAnchorEl] = useState(null);
  const [states, setState] = useState([]);
  const {company_id} = useContext(Categoriescontext);
  
  const setdata = (sessionID) =>{
    const param = {
      "sessionID" : sessionID,
      "Action":"getuserinfo",
      "company_id":company_id  
    };
    axios.post(`${API_url}`, param)
      .then(res => {
          const data = res.data;
          setState({"companyname":data.companyname,"username":data.username});
          
      });  

  };
  useEffect(() => {
    setdata(sessionID);
    
  },[sessionID]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
  
    return color;
  }
  function stringAvatar(name="") {
    if(name === ""){
      return ;
    }
    else{
      return {
        sx: {
          fontSize: 18, fontWeight: 500, width: 40, height: 40,bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}`,
      };
    }
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Tooltip title={states.username}>
        <Avatar {...stringAvatar(states.username)} onClick={handleClick}/>
        </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{top:"7px"}}
      >
        <div className="quick-user">
          <div className="quick-user_head">
            <Typography variant="div" className="quick-user_title">User Profile</Typography>
            <IconButton size="medium" sx={{ml: 'auto'}} ><Close /></IconButton>          
          </div>
          <Box className="quick-info" sx={{display:'flex', alignItems: 'center'}}>
            <Avatar {...stringAvatar(states.username)}  />
            <Box  sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',      
              ml:2            
            }}>
              <Box variant="div" className="user-name">{states.username}</Box>
              <Box component="span">Company: {states.companyname}</Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', mt:2}}>
            <Logoutapi sessionID={sessionID}/>
          </Box>

        </div>    
      </Popover>
    </div>
  );
}
