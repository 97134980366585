import React, { Component } from "react";
import {Typography} from '@mui/material';
import OTGmodal from '../OTGModal';
import axios from 'axios';
import Errormsg from '../../Errormsg';
import { API_url } from "../../../API_url/API_url";
import {Categoriescontext} from '../../../context/authvendx';
export class OTGgrid extends Component {
  static contextType = Categoriescontext;
  constructor(props) {
    
    super(props);
    this.state = {
      page : 1,
      pagesize: 10,
      item:[],
      filteritem:[],
      loading : false,
      header:[],
      filterDataCurrentPage : 1,
      isfilteractive:false,
      params:{        
        action: "OTG_Action",
        submodule:"OTG" ,
        module_name : this.props.modulename,
        sessionID :this.props.sessionID
      },
      filterparams:{},
      totalfilteritemcount:0,
      totalitemcount:0,
      totalpage : 1,
      totalfilterpage:0,
      date :this.props.date,
      button: false,
      counter:0,
      error : false ,
      error_msg : ''
    };
  
    this.footerchange = this.footerchange.bind(this);
    this.setdata = this.setdata.bind(this);
    this.filtermodalchange = this.filtermodalchange.bind(this);
    this.pagewisegetdata = this.pagewisegetdata.bind(this);
  }
  componentDidMount(){
    this.setdata();
  }
  setdata=(pagechange = 0)=>{
    const {company_id} = this.context;
    this.setState({
      loading : true,
     });
    let paramdate = [new Date(this.state.date[0]).toISOString(),new Date(this.state.date[1]).toISOString() ];

    const {isfilteractive,filterparams,filterDataCurrentPage,params,page} = this.state;

    let api_params =
            isfilteractive ? 
                {...filterparams,"page":(pagechange === 1 ? filterDataCurrentPage+1 :filterDataCurrentPage),"date":paramdate,"company_id":company_id} 
                : {...params,"page":(pagechange === 1 ? page+1 :page),"date":paramdate,"company_id":company_id};

    axios.post(`${API_url}`,api_params )
    .then
    ((res)=>{
      const value = res.data;
      this.setState({
        loading : false
      });
     if("error_msg" in value){
      this.setState({
        error : true,
        error_msg : value.error_msg
      });
     }
     else{
     
      const rows=[];
      const headers=[];
      this.state.isfilteractive ? this.setState({
        totalfilteritemcount :value["total_rows"],
        filterDataCurrentPage : value["currentpage"],
        totalfilterpage :value["totalpage"]
        }) 
        :this.setState({
          totalitemcount :value["total_rows"],
          page : value["currentpage"],
          totalpage :value["totalpage"]
      }); 
      if(value["header"] !== undefined  ){
        if(this.state.isfilteractive === false){
          value["header"]["static"].forEach((e)=>{
            let hash = {
              "field":e,
              "headerName":e,
              "width":170
            };
            if(e === 'id'){
              hash["type"] = "number";
            }
            headers.push(hash);
          });
          if(value["header"]["data"] !== undefined  ){
          Object.keys(value["header"]["data"]).forEach((e) => {
            headers.push({
              "field":e,
              "headerName":value["header"]["data"][e] === null ? null :`${value["header"]["data"][e]}(${e})`,
              "width":170
            });
          });
        }
        this.setState(currentstate =>{
          let finalRows = [...currentstate.header, ...headers];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          return { header :uniqueRows }
        }); 
        
        }
      }
      Object.keys(value["data"]).forEach((e,values)=>{
        rows.push(value["data"][e]);
      });
      if(pagechange === 1){
        if(this.state.isfilteractive) {
          this.setState(currentstate =>{

            let finalRows = [...currentstate.filteritem, ...rows];
            let jsonObject = finalRows.map(JSON.stringify);
            let uniqueSet = new Set(jsonObject);
            let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
            return { filteritem :uniqueRows }
          }) 
        }
        else{
          this.setState(currentstate =>{
            return { item :[...currentstate.item, ...rows]}
          });
        } 
      }
      else{
        this.state.isfilteractive ? this.setState({
          filteritem :rows
        }) :this.setState({
              item :rows
          }) ; 


      }
    }
   });     
  }

footerchange(){
  return (
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Typography>
            {!this.state.isfilteractive
              ? `Showing ${this.state.item.length} / ${
                this.state.totalitemcount
                }`: `Showing ${this.state.filteritem.length} / ${this.state.totalfilteritemcount}`}

          </Typography>
        </div>
      );

}
filtermodalchange =(params) =>{
  const filterhash = [];
  params.items.forEach((filter) =>{
    if (filter.value || filter.operatorValue === "isEmpty" || filter.operatorValue === "isNotEmpty") {
      filterhash.push(filter);
    }

  });
  if(Object.keys(filterhash).length === 0){
    this.setState({ isfilteractive:false,filterparams : this.state.params});

  }else{

    let filterparam =  {...this.state.params,"filterhash":filterhash};
    this.setState(currentstate =>{
       return {isfilteractive:true,filterDataCurrentPage :1,filterparams :filterparam ,filterpagination :true }
    }, ()=>{
      this.setdata();
    });
    
    
  }
}

pagewisegetdata =(param) =>{
  this.setState({counter:this.state.counter+1},()=>{
    if(this.state.counter >1){
      if( this.state.isfilteractive ) {
        if(this.state.filterDataCurrentPage !== this.state.totalfilterpage){
          this.setdata(1);
        }
      }
      else{
        if(this.state.page !== this.state.totalpage){
            this.setdata(1);
          }
        }

    }

  });
 

}  
  render() {
    const {error,error_msg,loading,header,isfilteractive,filteritem,item,pagesize} = this.state;
    return (
      <>
      <Errormsg key= {error} error={error} error_msg={error_msg} sk={"ss"}/>
      <OTGmodal
      
          loading={loading}
          columns={header}
          rows={isfilteractive ? filteritem:item}
          pageSize={pagesize}
          handleScrollEnd = {this.pagewisegetdata}
          handleFilterModelChange = {this.filtermodalchange}
          footerchanges = {this.footerchange}
          onSelectionModelChange = {this.selectionmodal}
          />

      </>
    );
  }
}
export default OTGgrid;
