import React from 'react';
import './header.css';
import { AppBar, Box, Toolbar, IconButton ,Typography} from '@mui/material';
import logo from '../../assets/logo/vendx-logo.png';
import { Menu } from '@mui/icons-material';
import Userinfo from "../../components/Userprofile/Userinfo";
// const AppBars = styled(AppBar)`
//   &.MuiPaper-root {
//     background-color: #ffffff;
//     margin-left: 7%;
//     // height:46px;
//     // max-width: 90vw;
//     // margin-top: 1.5rem;
//   }
// `;
// const Toolbars = styled(Toolbar)`
//   &.MuiToolbar-root {
//     min-height:46px;
//   }
// `;


export default class AppBarHeader extends React.Component {  
 
  constructor(props) {
    super(props);
    this.state = {
      openDrawer: false,
      anchorEl:null
    };
    
  }

  handleToggle = () => {
    this.setState({ openDrawer: !this.state.openDrawer });
  };
  handleClose = () =>{
    this.setState({anchorEl:null});
  }
  handleClick = (event) => {
    this.setState({anchorEl:event.currentTarget});
  };
    render() {
      return (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" >
            <Toolbar >
            <IconButton
                onClick={this.props.handleDrawerOpen}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 0, width: 64, height: 64 }}
              >
                <Menu />
              </IconButton>
              <div className="applogo" >
                <img src={logo} alt="Vendx Logo" />
              </div>
              
              <Typography variant="h5" component="div" sx={{ flexGrow: 1 ,color: 'black', ml:2}}>
                Integration Log
              </Typography>
              
              <Box sx={{ flexGrow: 1, }} />
              <Userinfo sessionID={this.props.sessionID}/>
            </Toolbar>
          </AppBar>  
          </Box >
      );
    } 
}

 