import React, { Component } from "react";
import { Route ,Switch,withRouter} from "react-router-dom";
import {  CssBaseline } from '@mui/material';
import OTGlog from "./routes/OTGlog";
import theme from './theme.js';
import {  ThemeProvider } from '@mui/system';
import IntegrationlogHome from "./layout/Main/IntegrationlogHome";
import MainPage from "./routes/MainPage";
import { LicenseInfo } from '@mui/x-data-grid-pro';

class App extends Component {
  constructor() {
    super();
    this.state = {
      sessionID:'',
      error:false,
      error_msg : ""
    };
    
  }
  componentDidMount() {
    LicenseInfo.setLicenseKey(
      'b1a40bf0e39bf97b5fed8e32f6cc08d4T1JERVI6Mzk0MDYsRVhQSVJZPTE2Nzg1Mjg1NDEwMDAsS0VZVkVSU0lPTj0x',
    );
  }

  setSessionID =( id) => {

    if (!this.state.sessionID)
    this.setState({
      sessionID: id
    })
  }
  render() {
    const {sessionID} = this.state;
    return (
      <ThemeProvider theme={theme}>
       <CssBaseline>
       <IntegrationlogHome sessionID={sessionID}> 
       { sessionID ? '' :(<Route
          exact  
                path="/"
                component={(propsmain)=><MainPage {...propsmain} setsessionID={this.setSessionID} />}
              />)}
       <Switch>
         
            <Route
                path="/OTG/:otg_type"
                component={(propsmain)=><OTGlog {...propsmain} sessionID={sessionID} setsessionID={this.setSessionID} loading/>}
              />
          </Switch>
        </IntegrationlogHome>  
       </CssBaseline>
       </ThemeProvider>
    );  
  }
}

export default withRouter(App); 
