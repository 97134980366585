import axios from "axios";
import {Button} from '@mui/material';
import {Logout } from '@mui/icons-material';
import {API_url } from "../../API_url/API_url";

const Logoutapi =({sessionID})=>{
    
    const logoutapi =()=>{

        const param = {
            "sessionID" : sessionID,
            "Action":"logout"  
        };
        axios.post(`${API_url}`, param)
        .then(res => {
        if("error" in res.data){
            
        }
        else{   
            window.location =`${res.data.hostadress}`;
        };
            
        });  

    };
    return (
        <>
        <Button variant="text" color="secondary" endIcon={<Logout color="primary" />} sx={{ml: 'auto'}}
             onClick={logoutapi}>
                 Logout
        </Button>
        </>
    );
};

export default Logoutapi ;

