import React, { Component } from "react";
import PO from "../components/Integrationlog/PO"; 
import DS from '../components/Integrationlog/DS'; 
import ASN from '../components/Integrationlog/ASN'; 
import GRN from '../components/Integrationlog/GRN'; 
import RR from '../components/Integrationlog/RR'; 
import POconfirm from '../components/Integrationlog/POconfirm'; 
export class OTGlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionID:this.props.sessionID
    };
    this.renderSwitch = this.renderSwitch.bind(this);
  }
  
  componentDidMount(){
    const queryParams = new URLSearchParams(this.props.location.search);
    let SessionID = queryParams.get('q');
    if(!this.state.sessionID && SessionID){
      this.setState({sessionID:SessionID});
      this.props.setsessionID(SessionID);
    }
    
    
  }
renderSwitch(e){
  const {sessionID} = this.state;
  switch (e) {
    case "PO":
      return <PO sessionID={sessionID} /> ;
    case "DS":
      return <DS sessionID={sessionID}/> ;
    case "POConfirm":
      return <POconfirm sessionID={sessionID}/>;
    case "ASN":
      return <ASN sessionID={sessionID}/>;  
    case "GRN":
        return <GRN sessionID={sessionID}/>;  
    case "RR":
      return <RR sessionID={sessionID}/>;  
    default:
      return <PO sessionID={sessionID}/>;
  }

}

  render() {
    return (
          <>
              {this.renderSwitch(this.props.match.params.otg_type)}
              </>
    );
  }
}

export default OTGlog;

