import Typography from '@mui/material/Typography';
import React, { Component } from "react";
import { OTGdatagrid } from "./style";
import { GridOverlay} from '@mui/x-data-grid-pro';
class OTGmodal extends Component {
  render() {
    const {loading,rows,columns,handleScrollEnd,onSelectionModelChange,selectionModel,error,handleFilterModelChange,footerchanges} = this.props;
    return (
      <div style={{ height: "483px", width: "100%" }} >
        <OTGdatagrid
          loading={loading}
          rows={rows}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          hideFooterPagination
          onRowsScrollEnd={handleScrollEnd}
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectionModel}
          components={{
            NoRowsOverlay: () => {
              return (
                <GridOverlay>
                  <Typography>
                    {error ? error : "No data found"}
                  </Typography>
                </GridOverlay>
              );
            },
            Footer: () => {
              return (
                <div>
                  {footerchanges()}
                </div>
              );
            }
          }}
          filterMode="server"
          onFilterModelChange={handleFilterModelChange}
        />
      </div>
    );
  }
}
export default OTGmodal;
