import { DataGridPro } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';
// import { XGrid } from "@material-ui/x-grid";
// export const OTGModal = XGrid;
export const OTGdatagrid = styled(DataGridPro)`
& .MuiDataGrid-columnHeaders {
  background-color: #edf4fc;
} 
.MuiDataGrid-columnHeaderTitle{
  text-align: left;
  color: blue;
  text-transform: uppercase;
  font-weight: 800 !important;
}`;
