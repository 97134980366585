import React,{ useState } from 'react';
import { Snackbar,Alert } from '@mui/material';
const Alertmui = React.forwardRef(function Alertmui(props, ref) {
    return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const Errormsg = ({error,error_msg}) =>{
    const [errorlog,seterror] = useState(error);
    const handleClose =()=>{
        seterror(false);
    };
    return (
        <>
            <Snackbar open={errorlog} autoHideDuration={6000} onClose={handleClose}>
                <Alertmui onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  {error_msg}
                </Alertmui>
              </Snackbar> 
        </>
    );
}

export default Errormsg ;