import React, { Component } from 'react';
import Drawer from '@mui/material/Drawer';
import Sidebarmenu from '../../components/Sidebarmenu/SideBarmenu';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
      const {open,sessionID} = this.props;
        return ( 
            <Drawer
            open={open}
            variant="persistent"
            anchor="left"        
            sx={{  
              width: 240,
              flexShrink: 0,
              
              [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box','background-color': '#ffffff', marginTop:'64px', height: 'calc( 100vh - 64px)',},
            }} 
          >
            <Sidebarmenu sessionID={sessionID}/>
           
          </Drawer>
         );
    }
}
 
export default Sidebar;
