
import {
  createTheme, 
} from '@mui/material/styles';



export const VendxTheme = createTheme({
  palette: {
    mode: 'light',    
    background: {      
      default: '#EBEEF2',      
    },
    text: {
      primary:"#1f2b3a",
      secondary:"rgba(90, 103, 120, 1)",
      //disabled:"rgba(0, 0, 0, 0.38)",
      //hint:"rgba(0, 0, 0, 0.38)"
    },
    primary: {
      light: '#3a88f2',
      main: '#096bef',
      dark: '#064aa7',
    },
    secondary: {
      light:'#4b5561',
      main: '#1f2b3a',
      dark: '#151e28'
    },
    success: {
      light: '#7bd7be',
      main: '#5bceae',
      dark: '#3f9079'
    },
    info: {
      light: '#33cfe0',
      main: '#00c3d9',
      dark: '#008897'
    },
    warning: {
      main: '#f4bd0e',
    },
    error: {
      main: '#ff5547',
    },
    grey: {
      500: '#A6C1D3'
    }

  },
  typography: {
    color: '#5a6778',
    htmlFontSize: 18,    
  },  
  HtmlBody: {
    backgroundColor: '#EBEEF2',    
  },
  borderBottom: {
    borderColor: '#ff5547',
  },
  components: {        
    MuiAppBar: {
      styleOverrides: {
        root: {          
          backgroundColor: '#ffffff',
          //color: '#000',
          boxShadow: '0 3px 10px 2px rgba(0, 0, 0, .1)',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:hover':{
            boxShadow: 'none',
          }
        },
      },
    }, 
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#3c4d62'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fieldset: {
            borderColor: '#a6c1d3'
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(166, 193, 211, 0.3)',
          '&:hover':{
            backgroundColor: 'rgba(166, 193, 211, 0.5)',
          },
          ":focus":{
            backgroundColor: 'rgba(166, 193, 211, 0.3)',
          },
          ":before": {
            borderColor: '#a6c1d3'
          }         
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root:{
            margin: 0,
            // width: 42,
            // height: 24,
            padding: 0,
            display: 'flex',
            '&:active': {
              '& .MuiSwitch-thumb': {
                // width: 16,
                // height: 16
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(12px)',
              },
            },
            '& .MuiSwitch-switchBase': {
               //padding: 4,               
              '&.Mui-checked': {
                transform: 'translateX(15px)',                
                color: '#fff',
                '& + .MuiSwitch-track': {
                  opacity: 1,                 
                },
                '& .MuiSwitch-thumb': {
                  backgroundColor: '#FFF',
                },
              },
            },
            '& .MuiSwitch-thumb': {              
              // width: 16,
              // height: 16,
              borderRadius: 100,
              backgroundColor: '#a6c1d3',
              boxShadow: 'none',
              // transition: theme.transitions.create(['width'], {
              //   duration: 200,
              // }),
            },
            '& .MuiSwitch-track': {
              borderRadius: 100,
              opacity: 1,
              border: 'solid 1px #a6c1d3',
              // backgroundColor: 'transparent',              
              //theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
              //boxSizing: 'border-box',
            },
          
      },

      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: '#A6C1D3',
          fontsize: 14,           
        }       
      }
    },
    MuiCardHeader:{      
      styleOverrides: {
        root: {
          borderColor: '#A6C1D3',
          border: '4px'           
        }       
      }
    },
    MuiCheckbox: {
      styleOverrides:{
        root:{
          color: '#A6C1D3',
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
         // backgroundColor: 'red',
         '& .MuiDataGrid-columnHeaders': {
          backgroundColor: 'red',  
                  
         },
         
        }      
      }
    },
    
   
  },
  


})


export default VendxTheme;