import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HashRouter as Router } from "react-router-dom";
import CategoriesProvider from './context/authvendx'
ReactDOM.render(
  <React.StrictMode>
    <Router >
      <CategoriesProvider>
        <App />
      </CategoriesProvider>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
