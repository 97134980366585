
import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import Sidebar from "../sidebar/Sidebar";
import AppBarHeader from "../Header/Header";
import {Card} from '@mui/material';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1.5),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginTop : "64px",
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
      marginTop : "64px",
    }),
  }),
);
export class IntegrationlogHome extends Component {
  constructor(props) {
    super(props);
    this.state={
        open:false
    };
  }
  inoff = (e)=> {
    this.setState({open :(!this.state.open)});
  };
  render() {
    return (
      <div>
        <AppBarHeader handleDrawerOpen={this.inoff} sessionID={this.props.sessionID}/>

        <Sidebar open ={this.state.open} sessionID={this.props.sessionID}/>
        <Main open={this.state.open}>
        <Card  variant="outlined" sx={{ minWidth: 275 }}>
            {this.props.children}
        </Card>
        </Main>
      </div>
    );
  }
}
export default IntegrationlogHome;
