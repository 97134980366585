import { createContext, Component } from "react";
export const Categoriescontext = createContext();

class CategoriesProvider extends Component {
    constructor(props) {
        super();
        this.state = {
            company_id : '',
        };
        this.setcompany_id = this.setcompany_id.bind(this);
      }
      
      setcompany_id = (company_id)=>{
        this.setState({company_id:company_id});

    };

    render() {
        const setcompany_id = this.setcompany_id;
        const {company_id} = this.state;
        const value = {company_id,setcompany_id};
        return (
            <Categoriescontext.Provider value={value}>{this.props.children}</Categoriescontext.Provider>
            
        )
    }
}


export default CategoriesProvider;
