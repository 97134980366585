import React, { Component } from 'react'
import axios from 'axios';
import Errormsg from '../components/Errormsg'; 
import { API_url } from '../API_url/API_url';
import {Categoriescontext} from '../context/authvendx';
class MainPage extends Component {
  static contextType = Categoriescontext;
    constructor(props) {
        super();
        this.state = {
          sessionID:'',
          error:false,
          error_msg : ""
        };
        
      }
      
      componentDidMount(){
        const authcontext = this.context;
        const queryParams = new URLSearchParams(this.props.location.search);
        let SessionID     = queryParams.get('q');
        let company_id     = queryParams.get('company_id');
        authcontext.setcompany_id(company_id);
        axios.post(`${API_url}`, {"sessionID" : SessionID,"company_id":company_id})
        .then(res => {
          let {error_msg} = res.data;      
          if(error_msg === undefined){
            this.setState({error:false});
            this.props.setsessionID(SessionID);
            this.props.history.push({
              pathname : '/OTG/PO',
              search : `?q=${SessionID}`
            })
          }
          else{
            this.setState((currentstatrte)=>{ return { ...currentstatrte,error:true,error_msg:error_msg}});
          }
          });
    }
    render() {
        const {error,error_msg} = this.state;
        return (
            <div>
              <Errormsg key= {error} error={error} error_msg={error_msg}/>
            </div>
            
        )
    }
}

export default MainPage