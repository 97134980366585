const PARENT_HOST = {
    "default"                  : "https://vend-x.com",
    "logs-static.vend-x.com"   : "https://vend-x.com",
    "logs-static.ej-buy.com"        : "https://ej-buy.com",
    "logs-static-utcl.vend-x.com"   : "https://ultratechcement.vend-x.com",
    "logs-testing-static.vend-x.com": "https://testing.vend-x.com",
    // "d3e10ikkwtx8ge.cloudfront.net" : "https://testing181.vend-x.com"
};

const currentHost  = window.location.host;
const parentHostURI= PARENT_HOST[currentHost] || PARENT_HOST['default'];

export const API_url = `${parentHostURI}/cgi-bin/vendx/integrationlog.cgi`;    