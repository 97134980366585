import React, { useState,useEffect,useContext } from 'react'
import { makeStyles } from "@mui/styles";
import axios from 'axios';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, } from '@mui/material'
import { NavLink } from "react-router-dom";
import { ExpandLess, ExpandMore ,FiberManualRecordOutlined } from '@mui/icons-material';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { hasChildren } from "./util";
import { API_url } from '../../API_url/API_url';
import {Categoriescontext} from '../../context/authvendx';
  const MenuStyle = makeStyles( (theme) => ({
    
    MenuMainLink: {   
      color: '#020202',      
      '& $MenuItemIcon': {
        color: '#3c4d62',      
      },
      "&:hover": {  
        color: theme.palette.primary.main,         
        '& $MenuItemIcon': {
          color: theme.palette.primary.main
        }
      }
    },
    MenuItemIcon: {
      minWidth: 30,
      height:24
    },
    MenuArrowICon: {
      color: '#a6c1d3',
      width: 19,
    }
  }));

export default function AppMenu(props) {  
  const [data,setdata] = useState({});
  const sessionID = props.sessionID;
  const {company_id} = useContext(Categoriescontext);
  useEffect (() => {
    const param = {
      "sessionID" :  sessionID,
      "Action":"getsidebarname",
      "company_id":company_id  
    };
    if(sessionID !== ''){
      axios.post(`${API_url}`, param)
        .then(value => {
          const data = value.data;
          if("error_msg" in data){
              
          }
          else{
              setdata(data);
        
        } 
      });  
    };
  },[sessionID]);

  const menu = [];
  Object.keys(data).forEach((subitem)=>{
    const items =[];
    data[subitem].forEach(item=>{
      const sk =
      {
        icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
        title: item,
        to: `/OTG/${item}?q=${sessionID}`,    
      };
      items.push(sk);

    });
    const menus1 = {
      icon: <HomeOutlinedIcon />,
      title: subitem,
      items :items
    };
    menu.push(menus1);
  });

  return menu.map((item, key) => <MenuItem key={key} item={item} />);  
}

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
  const classes = MenuStyle();
  return (
    <ListItem  className={classes.MenuMainLink}  component={NavLink} activeClassName="Mui-selected" to={item.to} disablePadding>
      <ListItemButton component="span">
        <ListItemIcon className={classes.MenuItemIcon}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItemButton>
    </ListItem>
  );
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen((prev) => !prev);
  }; 

  const classes = MenuStyle();

  return (
    <React.Fragment>
      <ListItem component="li" onClick={handleClick} className={classes.MenuMainLink} disablePadding>
        <ListItemButton component="a">
        <ListItemIcon className={classes.MenuItemIcon}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLess className={classes.MenuArrowICon} /> : <ExpandMore className={classes.MenuArrowICon} />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="ul" disablePadding>
          {children.map((child, key) => (
            <MenuItem component="a" key={key} item={child} />
          ))}
        </List>
      </Collapse>      
    </React.Fragment>
  );
};

